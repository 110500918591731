<template>
  <span :class="colorClass">
    <v-chip color="success" text-color="white" v-if="value">
      <v-icon small>check</v-icon>
    </v-chip>
    <v-chip color="error"  text-color="white" v-else>
      <v-icon small>close</v-icon>
    </v-chip>
  </span>
</template>

<script>
export default {
  name: 'Status',
  props: ['value', 'color'],
  computed: {
    colorClass() {
      if (this.color) {
        if (this.number === 0) {
          return '';
        } if (this.number > 0) {
          return 'success--text';
        }
        return 'error--text';
      }
      return '';
    },
  },
};
</script>
