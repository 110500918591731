<template>
  <section class="content px-4 py-2 my-2" name="MassiveOrders">
    <v-layout wrap>
      <v-flex xs12>
        <AppTitle title="Blog" subtitle="---" />
      </v-flex>
      <v-flex xs12>
        <v-card class="elevation-2">
          <v-toolbar class="elevation-0">
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="searchText"
              hide-details
              append-icon="search"
              placeholder="Buscar por nombre"
              flat
              solo
              single-line
              right
              @keyup.native="searchInputHandle"
            ></v-text-field>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="items"
            :pagination.sync="pagination"
            :rows-per-page-items="rowsPerPage"
            no-data-text="No hay articulos de blog"
            class="elevation-0"
          >
            <template v-slot:items="props">
              <td>
                <a
                  :href="`https://www.rosalinda.cl/blog/${props.item.url}`"
                  class="grey--text text--darken-1"
                  target="_blank"
                >
                  {{ props.item.name }}
                </a>
              </td>
              <td class="text-xs-center">
                <AppStatus :value="props.item.status"></AppStatus>
              </td>
              <td>
                {{ props.item.updated_at | moment('DD-MM-YYYY hh:mm') }}
              </td>
              <td class="text-xs-right">
                <router-link
                  style="text-decoration: none; position: relative;"
                  :to="{ name: 'settingsBlogPost', params: { id: props.item.id } }"
                >
                  <v-icon small class="mr-2">
                    edit
                  </v-icon>
                </router-link>

                <v-icon small @click="deleteItem(props.item.id)">
                  delete
                </v-icon>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-btn fab dark fixed bottom right color="success" @click="newItem()">
      <v-icon>add</v-icon>
    </v-btn>
  </section>
</template>

<script>
import { GET_BLOG } from '../../../config'
import AppTitle from '../../useful/title.vue'
import AppStatus from '../../useful/status.vue'

export default {
  name: 'SettingsBlogPosts',
  components: {
    AppTitle,
    AppStatus
  },
  data() {
    return {
      itemsAll: [],
      items: [],
      dialog: false,
      searchText: '',
      headers: [
        { text: 'Titulo', align: 'left', sortable: false },
        { text: 'Estado', align: 'center', sortable: false },
        { text: 'Ultima modificacion', align: 'left', sortable: false },
        { text: '', align: 'left', sortable: false }
      ],
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }]
    }
  },
  mounted() {
    this.getBlog()
  },
  methods: {
    async getBlog() {
      const res = await this.$http.get(GET_BLOG)
      this.items = res.data
      this.itemsAll = res.data
    },
    async deleteItemAction(id) {
      try {
        await this.$http.delete(`${GET_BLOG}/${id}`)
        this.getBlog()
        this.$store.dispatch('setConfirm', { active: false })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    deleteItem(item) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: `¿Esta seguro de eliminar este post?`,
        description: ``,
        action: () => this.deleteItemAction(item)
      })
    },
    newItem(e) {
      e = e === undefined ? 'nuevo' : e
      this.$router.push({ name: 'settingsBlogPost', params: { id: e } })
    },
    searchInputHandle() {
      const search = this.searchText
      if (search === '') {
        this.items = this.itemsAll
        return
      }
      let result = this._.filter(this.itemsAll, item => {
        const res = this._.includes(this._.lowerCase(item.name), this._.lowerCase(search))
        return res
      })
      this.items = result
      this.dialogSearch = false
    }
  }
}
</script>
